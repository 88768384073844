var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "photoboot" }, [
    _c(
      "div",
      { staticClass: "photoboot__draggables" },
      [
        !_vm.completed
          ? [
              _c("div", { staticClass: "pb-1" }, [
                _vm._v("PHOTOBOOTH (drag & drop)"),
              ]),
              _c("div", { staticClass: "photoboot__scrollable" }, [
                _c(
                  "div",
                  { staticClass: "photoboot__boot-list" },
                  _vm._l(_vm.images, function (image, index) {
                    return _c("img", {
                      key: image + "-" + index,
                      staticClass: "photoboot__boot-list--boot",
                      attrs: {
                        src: image,
                        alt: "photoboot",
                        draggable: "true",
                      },
                      on: {
                        dragstart: _vm.handleDragStart,
                        dragend: _vm.handleDragEnd,
                      },
                    })
                  }),
                  0
                ),
              ]),
            ]
          : [_vm._m(0)],
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "photoboot__image" },
      [
        _vm.isViewerAuditorLike
          ? _c("ResizableText", {
              staticClass: "h-full w-full",
              attrs: { message: "The users are taking pictures" },
            })
          : [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!_vm.track,
                      expression: "!!track",
                    },
                  ],
                  staticClass: "photoboot__container-wrap",
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.capturedImageUrl,
                        expression: "!capturedImageUrl",
                      },
                    ],
                    ref: "container",
                    staticClass: "photoboot__canvas-container",
                  }),
                  _vm.capturedImageUrl
                    ? _c("div", { staticClass: "photoboot__image-container" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.capturedImageUrl,
                            width: _vm.width,
                            height: _vm.height,
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.dragging && !_vm.capturedImageUrl
                    ? _c("div", {
                        staticClass: "photoboot__canvas-overlay",
                        on: {
                          drop: _vm.handleDrop,
                          dragover: _vm.handleDragOver,
                        },
                      })
                    : _vm._e(),
                ]
              ),
              !_vm.track
                ? _c(
                    "v-flex",
                    {
                      staticClass: "photoboot__spinner-container",
                      attrs: { grow: "", "justify-center": "" },
                    },
                    [
                      _c("v-progress-circular", {
                        staticClass: "photoboot__spinner",
                        attrs: { indeterminate: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.track
                ? _c("UserVideo", {
                    ref: "video",
                    staticClass: "photoboot__user-video",
                    attrs: { track: _vm.track },
                    on: {
                      ready: function ($event) {
                        return _vm.initialize()
                      },
                    },
                  })
                : _vm._e(),
            ],
      ],
      2
    ),
    _vm.canSubmit
      ? _c(
          "div",
          { staticClass: "photoboot__controls" },
          [
            !_vm.imageBlob && !_vm.completed
              ? [
                  _c(
                    "RtbButton",
                    {
                      key: "take-photo",
                      attrs: {
                        variant: "icon",
                        size: "lg",
                        circle: "",
                        title: "Take a photo",
                        disabled: _vm.working,
                        id: _vm._uid + "-take-photo-button",
                      },
                      on: { click: _vm.takePicture },
                    },
                    [
                      _c("svg-icon", {
                        staticStyle: { position: "relative", top: "-1px" },
                        attrs: { name: "camera-solid" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    {
                      key: "take-photo-tooltip",
                      attrs: {
                        target: _vm._uid + "-take-photo-button",
                        placement: "right",
                      },
                    },
                    [_vm._v(" Take a photo ")]
                  ),
                ]
              : _vm._e(),
            (!_vm.imageUrl && !!_vm.imageBlob && !_vm.completed) || _vm.working
              ? [
                  _c(
                    "RtbButton",
                    {
                      key: "submit",
                      attrs: {
                        variant: "icon",
                        size: "lg",
                        circle: "",
                        title: "Submit",
                        disabled: _vm.working,
                        id: _vm._uid + "-submit-button",
                      },
                      on: { click: _vm.submitPicture },
                    },
                    [_c("svg-icon", { attrs: { name: "checkmark" } })],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    {
                      key: "save-tooltip",
                      attrs: {
                        target: _vm._uid + "-submit-button",
                        placement: "right",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.working ? "Submitting..." : "Submit Photo"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.destroyables.length && !_vm.imageBlob && !_vm.completed
              ? [
                  _c(
                    "RtbButton",
                    {
                      key: "reset-1",
                      attrs: {
                        variant: "icon",
                        size: "lg",
                        circle: "",
                        dark: "",
                        title: "Reset",
                        animated: "",
                        id: _vm._uid + "-reset-button-1",
                      },
                      on: { click: _vm.resetBooth },
                    },
                    [_c("svg-icon", { attrs: { name: "undo-regular" } })],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    {
                      key: "reset-1-tooltip",
                      attrs: {
                        target: _vm._uid + "-reset-button-1",
                        placement: "right",
                      },
                    },
                    [_vm._v(" Reset ")]
                  ),
                ]
              : _vm._e(),
            _vm.imageBlob && !_vm.completed
              ? [
                  _c(
                    "RtbButton",
                    {
                      key: "reset-2",
                      attrs: {
                        variant: "icon",
                        size: "lg",
                        circle: "",
                        title: "Reset",
                        animated: "",
                        id: _vm._uid + "-reset-button-2",
                        disabled: _vm.working,
                      },
                      on: { click: _vm.retakePicture },
                    },
                    [_c("svg-icon", { attrs: { name: "undo-regular" } })],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    {
                      key: "reset-2-tooltip",
                      attrs: {
                        target: _vm._uid + "-reset-button-2",
                        placement: "right",
                      },
                    },
                    [_vm._v(" Reset ")]
                  ),
                ]
              : _vm._e(),
            _vm.completed && _vm.isMobile
              ? _c(
                  "RtbButton",
                  {
                    staticClass: "font-bold capitalize text-lg px-3",
                    staticStyle: { "min-width": "180px" },
                    on: {
                      click: function ($event) {
                        return _vm.takeNextPhoto()
                      },
                    },
                  },
                  [_vm._v(" Take Next Photo ")]
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "photoboot__result-text" }, [
      _c("div", [_vm._v("IMAGE "), _c("br"), _vm._v("SUBMITTED")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }